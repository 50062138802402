import Zip from "@/models/Zip";
import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript";

export enum AddressType {
    SHIPPING = "SHIPPING",
    BILLING = "BILLING",
    SHIPPING_BILLING = "SHIPPING_BILLING",
}

export enum AddressParentTypes {
    CLIENT = "CLIENT",
    POTENTIAL_CLIENT = "POTENTIAL_CLIENT",
    HEADQUARTER = "HEADQUARTER",
}

@JsonConverter
class AddressConverter implements JsonCustomConvert<AddressType> {

    deserialize(data: string): AddressType {
        return (<any>AddressType)[data]
    }
    serialize(data: AddressType): any {
        return data.toString()
    }

}

@JsonObject("Address")
export default class Address {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined

    @JsonProperty("address", String, true)
    address?: string = undefined
    @JsonProperty("postalCode", String, true)
    postalCode?: string = undefined
    @JsonProperty("population", String, true)
    population?: string = undefined
    @JsonProperty("province", String, true)
    province?: string = undefined
    @JsonProperty("phone", String, true)
    phone?: string = undefined
    @JsonProperty("email", String, true)
    email?: string = undefined
    @JsonProperty("fax", String, true)
    fax?: string = undefined
    @JsonProperty("cif", String, true)
    cif?: string = undefined
    @JsonProperty("observations", String, true)
    observations?: string = undefined

    @JsonProperty("addressType", AddressConverter, true)
    addressType?: AddressType = undefined


}

export interface AddressSelect {
    name: string,
    value: AddressType
}