





















































































































































































































import {Component, Vue, Watch} from "vue-property-decorator"
import OrderService from "@/services/OrderService"
import Order, {OrderSelect, OrderType} from "@/models/Order"
import Options from "@/models/vue/Options"
import ClientService from "@/services/ClientService"
import Client from "@/models/Client"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import User from "@/models/User"
import UserService from "@/services/UserService"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import LangModule from "@/store/LangModule"
import OrderDetail from "@/models/OrderDetail"
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"
import Company from "@/models/Company";
import CompanyService from "@/services/CompanyService";

@Component({components: { OrderStatusComponent }})
export default class OrdersManagementView extends Vue {
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	sendButtonDisabled = true
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	totalItems: number = 0
	search: string = ""
	orders: Order[] = []
	selected: Order[] = []

	headers = [
		{ align: "center", text: this.lang.date, value: "createdAt", 			width: "auto" },
		{ align: "center", text: this.lang.createdBy, value: "createdBy", width: "auto" },
		{ align: "center", text: this.lang.status, value: "status", 			width: "auto" },
		{ align: "center", text: this.lang.actions, value: "actions", 		width: "auto" },
		{ align: "center", text: "Xgest ID", value: "connectorId", 				width: "auto" },
	]

	headersWeb = [
    { align: "center", text: "Id", value: "id", 			width: "100px" },
		{ text: "Xgest ID", align: 'center', value: 'ref', width: 'auto' },
		{ text: this.lang.buyDateAtHeader, align: 'center', value: 'createdAt', width: 'auto' },
		{ text: this.lang.status, align: 'center', value: 'status', width: 'auto' },
		{ text: this.lang.createdBy, align: "center", value: "createdBy", width: "auto" },
		{ text: this.lang.total, align: 'center', value: 'totalAmount', width: 'auto' },
		{ text: this.lang.edit, align: 'center', value: 'edit', width: 'auto' },
	]

	options: Options = new Options()
	picker: string = ""
	dates: string[] = [this.picker]
	clients: Client[] = []
	client: Client = new Client()
	commercial: User = new User()
	commercials: User[] = []
	companies: Company[] = []
	// @ts-ignore
	orderType: OrderType | null = null

	//Filter models
	commercialId: number | null = null
	clientId: number | null = null

	sessionModule: SessionModule = getModule(SessionModule)
	isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]
	filterColumn: number = 4
	orderColumn: number = 8
	expand: boolean = true

	created() {
		this.watchOptions()
	}

	@Watch("options")
	async watchOptions() {
		// await ClientService.getClients(this, this.clients, 0, 10, "")
		await CompanyService.getCompanies(this, this.companies, 0, 10, "")
		await UserService.getCommercials(this, this.commercials, 0, 10, true, true)
		//Mantener la petición de pedidos en el ultimo lugar
		await OrderService.getOrders(this, this.orders, this.page - 1, this.itemsPerPage, this.search, null, null, this.dates[0], this.dates[1], null)
	}

	getTotalPrice(orderDetails: OrderDetail[]) {
		let totalPrice: number = 0
		orderDetails.forEach(orderDetail => { totalPrice += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity)) })
		return totalPrice
	}

	isSelectable(item: Order){
		return item.orderType !== OrderType.WAITING
	}

	@Watch("selected")
	sendBtnHidden(){
		console.log(this.selected)
		this.sendButtonDisabled = this.selected.length == 0
	}

	clearCommercial() {
		this.commercial = new User()
		this.watchOptions()
	}

	rowClick(order: Order) {
		this.$router.push('/order-details/' + order.id)
	}

	clearDateFilter() {
		this.dates = []
		this.watchOptions()
	}

}
