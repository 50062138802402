var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h4',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.orders))]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.orders,"headers":_vm.headersWeb,"hide-default-footer":"","height":"380px","page":_vm.page,"fixed-header":"","mobile-breakpoint":0,"no-data-text":_vm.loading ? _vm.lang.searching : _vm.lang.noResults,"disable-sort":"","options":_vm.options,"single-select":true,"items-per-page":_vm.itemsPerPage,"show-select":!_vm.isCommercial,"server-items-length":_vm.totalItems},on:{"update:items":function($event){_vm.orders=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"width":"100%","height":"40px"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.ref",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.search,"append-icon":"mdi-magnify"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.watchOptions($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.createdAt",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","outlined":"","width":"100%","min-height":"40px","color":"#9e9e9e"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.lang.filterByDate)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_vm._v("mdi-calendar-clock-outline")])],1)]}}],null,true)},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-date-picker',{attrs:{"year-icon":"mdi-calendar-blank","prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","multiple":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-card-actions',[_c('v-sheet',{staticClass:"mx-auto px-8 py-2",attrs:{"depressed":"","color":"pb-grey"},on:{"click":_vm.clearDateFilter}},[_vm._v(_vm._s(_vm.lang.clearFilters))]),_c('v-sheet',{staticClass:"mx-auto px-8 py-2",attrs:{"depressed":"","color":"pb-success"},on:{"click":_vm.watchOptions}},[_vm._v(_vm._s(_vm.lang.search))])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByCompany,"items":_vm.companies,"outlined":"","autocomplete":"null","item-text":"name","item-value":"value","clearable":""},on:{"change":_vm.watchOptions},model:{value:(_vm.orderType),callback:function ($$v) {_vm.orderType=$$v},expression:"orderType"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)]}},{key:"header.createdBy",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByCommercial,"outlined":"","item-text":"commercialName","item-value":"id","disabled":_vm.isCommercial,"items":_vm.commercials,"hide-no-data":"","hide-selected":"","disabled":"","clearable":"","placeholder":_vm.lang.typeToSearch},on:{"change":_vm.watchOptions,"click:clear":_vm.clearCommercial},model:{value:(_vm.commercialId),callback:function ($$v) {_vm.commercialId=$$v},expression:"commercialId"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.totalAmount",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-0 transparent font-weight-medium custom-label-color",attrs:{"hide-details":"auto","dense":"","flat":"","label":_vm.lang.filterByClient,"clearable":"","disabled":"","items":_vm.clients,"outlined":"","item-text":"name","item-value":"id","autocomplete":"null"},on:{"change":_vm.watchOptions,"click:clear":_vm.clearCommercial},model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"header.edit",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"rounded-0 custom-label-color font-weight-medium",attrs:{"disabled":"","clearable":"","hide-details":"auto","outlined":"","dense":"","color":"indigo","label":_vm.lang.filterByProduct,"append-icon":"mdi-magnify"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.watchOptions($event)}}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium"},[_vm._v(_vm._s(header.text))])])],1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.createdAt.setLocale("es").toFormat('dd/MM/yy $ HH:mm').replace("$", _vm.lang.hour + ":"))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('OrderStatusComponent',{attrs:{"orderType":item.orderType}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.createdBy.email)+" ")])]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.getTotalPrice(item.orderDetails).toFixed(2))+"€ ")])]}},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-bold grey--text text--darken-2"},[_vm._v(_vm._s(item.connectorId))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.$router.push('/order-management/' + item.id)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-magnify")])],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-card-actions',[_c('h5',{staticClass:"ml-auto font-weight-medium grey--text text--darken-1"},[_vm._v(_vm._s(_vm.lang.ordersTableTip))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }